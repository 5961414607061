export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_kf9tgV9aT",
    "USER_POOL_APP_CLIENT_ID": "30jf03ngolc926djip25ns7nta",
    "ROOT_API_URL": "https://kx376r657k.execute-api.us-east-1.amazonaws.com/exchange-poc-documents-api-prod",
    "MFA_ISSUER": "Precedent-exchange-poc",
    "S3_DEPLOYMENT_BUCKET": "exchange-poc-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:1324ecc2-6a55-4fb1-a92d-d1020e4d1dfb",
    "RUM_APP_MONITOR_ID": "69959ce3-8ee6-4942-a516-7283592d6238",
    "ENV_STAGE": "test"
}
